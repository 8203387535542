import React, { useState, createContext, useEffect } from "react";
import "./index.css";
import AppContext from "./AppContext";
import SearchField from "./components/SearchField";

var x = {
  key1: "first default value", //scalar value
  key2: "",
  key3: function () {
    //functions
  },
  key4: ["content1", "content2"], //collection
};

function App() {
  const [contextor, setContextor] = useState<any>(x);

  console.log(contextor);

  return (
    <div className="App">
      <AppContext.Provider value={{ contextor, setContextor }}>
        <div className="App-title">Search Below and filter by metrics:</div>
        {/* <div>{`parent context is now: ${contextor.key1 === undefined ? "was null" : contextor.key1}`}</div> */}
        <SearchField />
      </AppContext.Provider>
      {/* <header className="App-header"></header> */}
    </div>
  );
}

export default App;
