import React, { useState, useEffect, useContext } from "react";
import AppContext from "../AppContext";
import Results from "./Results";

let counter = 0;

var mock_results = [
  {
    domain: "globtrotersglobalworld.com",
    url: "https://thisisdomainhuehuehuehuehue.com/aksjdfajklsdf",
    title:
      "This is the homepage of the one and only and great and powerful trixie",
    rd: 30,
    traffic: 2000,
    da: 20,
    dr: 23,
    obl: 4,
    content:
      "this is the entire thing content OTHER homepage of the one and only and great and powerful trixie",
  },
  {
    domain: "whatintheworld.com",
    url: "https://asdfasdfasdfasdfmainhuehuehuehuehue.com/aksjdfajklsdf",
    title: "OTHER homepage of the one and only and great and powerful trixie",
    rd: 24,
    traffic: 5000,
    da: 40,
    dr: 63,
    obl: 14,
    content:
      "so this is the other thing full of controversy OTHER homepage of the one and only and great and powerful trixie",
  },
];

//fields: domain, url, title, content, rd, traffic, da, dr, obl (in that order)
// var domain = "https://localhost:7137";
var domain = "https://api.proskins.io";

const SearchField = (props: any) => {
  const cont = useContext(AppContext);

  const [rd_d, setRd_d] = useState<number>(20);
  const [traffic_d, setTraffic_d] = useState<number>(0);
  const [da_d, setDa_d] = useState<number>(0);
  const [dr_d, setDr_d] = useState<number>(0);
  const [rd_u, setRd_u] = useState<number>(100);
  const [traffic_u, setTraffic_u] = useState<number>(100000);
  const [da_u, setDa_u] = useState<number>(90);
  const [dr_u, setDr_u] = useState<number>(90);
  const [obl, setObl] = useState<number>(50);
  const [query, setQuery] = useState<string>("");

  const [searchButton, setSearchButton] = useState("Search");

  const [currentPage, setCurrentPage] = useState(1);
  const [wResults, setWResults] = useState<any[] | []>([]);

  const [errorMessage, setErrorMessage] = useState("...");

  const resulter = () => {
    const z = mock_results.map((arr: any) => (
      <Results
        key={arr.url}
        domain={arr.domain}
        url={arr.url}
        title={arr.title}
        rd={arr.rd}
        traffic={arr.traffic}
        da={arr.da}
        dr={arr.dr}
        obl={arr.obl}
        content={arr.content}
      />
    ));
    setWResults(z);
  };

  const search_req = (cp: number) => {
    if (query.length < 5) {
      return;
    }

    setWResults([<div className="lds-hourglass"></div>]);

    let jsonObject = {
      query: query,
      skip: cp,
      rd_d: rd_d,
      traffic_d: traffic_d,
      da_d: da_d,
      dr_d: dr_d,
      rd_u: rd_u,
      traffic_u: traffic_u,
      da_u: da_u,
      dr_u: dr_u,
      obl: obl,
    };

    console.log(jsonObject);

    (async () => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 15000);
      try {
        const rx = await fetch(`${domain}/api/Search/Search`, {
          method: "post",
          signal: controller.signal,
          body: JSON.stringify(jsonObject),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => res.json());
        console.log(rx);
        if (!rx.hasOwnProperty("msg")) {
          setErrorMessage("something is messed up");
        }
        if (rx.msg === "all good") {
          mock_results = rx.results;
          setErrorMessage("...");
          resulter();
        } else if (rx.msg === "no more results") {
          setWResults([<div></div>]);
          setErrorMessage(
            "No more results, try changing the metrics or searching for something else"
          );
        } else if (rx.msg === "remove filters") {
          setWResults([<div></div>]);
          setErrorMessage(
            "Results found but the filters hid them, change filters, or go to next/prev page or search for something else"
          );
        }
      } catch (error) {
        console.error(error);
        setWResults([<div></div>]);
        setErrorMessage(
          "No results, or server caught fire, try searching for something else"
        );
      } finally {
        clearTimeout(timeoutId);
      }
    })();
  };

  function clickyButton() {
    // counter++;
    // cont.key1 = "new value from child hue hue hue " + counter;
    // console.log(cont);
    // cont.setContextor(cont);
    setErrorMessage("...");
    // reset_to_0_metrics();
    setSearchButton("");
    setTimeout(() => {
      setSearchButton("Search");
    }, 1000);
    setCurrentPage(1);
    search_req(1);
  }

  function onlyDigits(input: any) {
    return input
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1")
      .replace(/^0[^.]/, "0");
  }

  const rdHandler_d = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setRd_d(x);
  };
  const trafficHandler_d = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setTraffic_d(x);
  };
  const daHandler_d = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setDa_d(x);
  };
  const drHandler_d = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setDr_d(x);
  };
  const rdHandler_u = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setRd_u(x);
  };
  const trafficHandler_u = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setTraffic_u(x);
  };
  const daHandler_u = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setDa_u(x);
  };
  const drHandler_u = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setDr_u(x);
  };

  const oblHandler = (event: any) => {
    let x = 0;
    let y: number = +event.target.value;
    if (typeof y == "number") {
      x = y;
    }
    setObl(x);
  };

  const queryHandler = (event: any) => {
    setQuery(event.target.value);
  };

  function prev_click() {
    setWResults([<div className="lds-hourglass"></div>]);
    setErrorMessage("...");
    if (currentPage <= 1) {
      return null;
    }
    //request wResults too
    //go to previous otherwise
    var cur_page = currentPage;
    cur_page--;
    setCurrentPage(cur_page);
    // reset_to_0_metrics();
    search_req(cur_page);
  }
  function next_click() {
    //request wResults too
    setWResults([<div className="lds-hourglass"></div>]);
    var cur_page = currentPage;
    cur_page++;
    setCurrentPage(cur_page);
    // reset_to_0_metrics();
    search_req(cur_page);
  }

  useEffect(() => {
    resulter();
  }, []);

  return (
    <div className="grid">
      {/* <p>{`child context: ${cont.contextor.key1}`}</p> */}
      <div className="left-pane">
        <div className="search-fields">
          <div>
            <div>RDs:</div>
            <input type="number" value={rd_d} onChange={rdHandler_d} />
            <input type="number" value={rd_u} onChange={rdHandler_u} />
          </div>
          <div>
            <div>Traffic:</div>
            <input
              type="number"
              value={traffic_d}
              onChange={trafficHandler_d}
            />
            <input
              type="number"
              value={traffic_u}
              onChange={trafficHandler_u}
            />
          </div>
          <div>
            <div>DA:</div>
            <input type="number" value={da_d} onChange={daHandler_d} />
            <input type="number" value={da_u} onChange={daHandler_u} />
          </div>
          <div>
            <div>DR:</div>
            <input type="number" value={dr_d} onChange={drHandler_d} />
            <input type="number" value={dr_u} onChange={drHandler_u} />
          </div>
          <div>
            <div>OBL:</div>
            <input type="number" value={obl} onChange={oblHandler} />
          </div>
        </div>
        <div className="search-fields-2">
          <input
            type="text"
            value={query}
            onChange={queryHandler}
            placeholder="Search for something here..."
          />
          <button className="search-button" onClick={clickyButton}>
            {searchButton}
          </button>
        </div>
        <div className="separator"></div>
        <div className="indicators">
          <div className="ind-domain">{"Domain (click to copy)"}</div>
          <div className="ind-url">
            {"Url (click to copy full url to clipboard)"}
          </div>
          <div className="ind-title">
            {"Title (hover to view/close content)"}
          </div>
          <div className="ind-metric">RD</div>
          <div className="ind-metric">Traffic</div>
          <div className="ind-metric">DA</div>
          <div className="ind-metric">DR</div>
          <div className="ind-metric">OBL</div>
        </div>
        <div className="search-results-parent">
          <div>{wResults}</div>
        </div>
        <div className="previous-next">
          <button className="nav-button" onClick={prev_click}>
            Prev
          </button>
          <div className="current-page">{currentPage}</div>
          <button className="nav-button" onClick={next_click}>
            Next
          </button>
        </div>
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  );
};

export default SearchField;
