import React, { useState, useEffect, useContext } from "react";
import "../index.css";
function copyDivToClipboard(text: string) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}
function Results(props: any) {
  const [url, setUrl] = useState(props.url);
  const [domain, setDomain] = useState(props.domain);
  const [openMenu, setOpenMenu] = useState(false);
  const copyResults = () => {
    try {
      copyDivToClipboard(props.url);
      setUrl("copied...");
      setTimeout(() => {
        setUrl(props.url);
      }, 1000);
    } catch (error) {}
  };
  const copyDomain = () => {
    try {
      copyDivToClipboard(props.domain);
      setDomain("copied...");
      setTimeout(() => {
        setDomain(props.domain);
      }, 1000);
    } catch (error) {}
  };
  const show_content = () => {
    //this is a pop up that displays content
    console.log("showing content");
    setOpenMenu(true);
  };
  const hide_content = () => {
    //this is a pop up that displays content
    console.log("hiding content");
    setOpenMenu(false);
  };
  return (
    <div className="Results-main">
      <div className="domain" onClick={copyDomain}>
        {domain.substring(0, 20)}
      </div>
      <div className="url" onClick={copyResults}>
        {url
          .substring(0, 55)
          .replace("https://", "")
          .replace("http://", "")
          .replace("www.", "")}
      </div>
      <div
        className={`title ${openMenu ? "clicked-cont" : ""}`}
        onMouseOver={show_content}
        onMouseLeave={hide_content}
      >
        {props.title.substring(0, 50)}
      </div>
      <div className="metric">{props.rd}</div>
      <div className="metric">{props.traffic}</div>
      <div className="metric">{props.da}</div>
      <div className="metric">{props.dr}</div>
      <div className="metric">{props.obl}</div>
      <div
        className={`cont-cont ${openMenu ? "open" : ""}`}
        onMouseEnter={show_content}
        onMouseLeave={hide_content}
      >
        {`${"TITLE: " + props.title + "\n\n ---- CONTENT: " + props.content}`}
      </div>
    </div>
  );
}

export default Results;
